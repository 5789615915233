import { Pagination, PaginationItem } from '@mui/material';
import { useGridApiContext, useGridSelector, gridPageCountSelector, gridPageSelector } from '@mui/x-data-grid-pro';

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const currentPage = useGridSelector(apiRef, gridPageSelector);

  const handleFirstPageButtonClick = () => {
    apiRef.current.setPage(0);
  };

  const handleLastPageButtonClick = () => {
    apiRef.current.setPage(pageCount - 1);
  };

  return (
    <Pagination
      count={pageCount}
      page={currentPage + 1}
      onChange={(_event, value) => apiRef.current.setPage(value - 1)}
      showFirstButton
      showLastButton
      renderItem={(item) => (
        <PaginationItem
          {...item}
          onClick={
            item.type === 'first'
              ? handleFirstPageButtonClick
              : item.type === 'last'
              ? handleLastPageButtonClick
              : item.onClick
          }
        />
      )}
    />
  );
}

export const TranslationDataGridProSx = {
  height: '95%',
  width: '100%',
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto'
  }
};
