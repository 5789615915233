// SettingsStyles.ts

import { SxProps, Theme } from '@mui/material/styles';

export const cardStyles = (selectedTheme: string, themeKey: string | undefined): SxProps<Theme> => ({
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  p: 2,
  border: (theme: Theme) => themeKey
    ? selectedTheme.toLowerCase() === themeKey.toLowerCase()
      ? `2px solid ${theme.palette.primary.main}`  // When the theme matches
      : '1px solid rgba(0, 0, 0, 0.12)'  // Default border when not selected
    : 'none',  // No border when themeKey is empty or undefined
});


export const containerBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  mb: 2,
};

export const cardContainerStyle: SxProps = {
  width: 150,
  height: 200,
  mr: 2,
};
