import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IThemeConfig } from '@ccs-dip/common/types/app-config';
import { useTranslation } from 'react-i18next';
import { sanitizeText } from 'utils/Utils';
import useThemeNotification from './useThemeNotification';

interface SettingsDialogProps {
  open: boolean;
  themeToEdit: IThemeConfig | null;
  onSave: (theme: IThemeConfig) => void;
  onClose: () => void;
}

export const SettingsDialog: React.FC<SettingsDialogProps> = ({ open, themeToEdit, onSave, onClose }) => {
  const { t } = useTranslation();
  const [newThemeName, setNewThemeName] = useState(themeToEdit?.title || '');
  const [isSelected,setIsSelected] = useState(themeToEdit?.isSelected || false);
  const [isDefault,setIsDefault] = useState(themeToEdit?.isDefault || false);
  const [primaryColor, setPrimaryColor] = useState(themeToEdit?.theme.palette.primary.main || '#000000');
  const [secondaryColor, setSecondaryColor] = useState(themeToEdit?.theme.palette.secondary.main || '#ffffff');
  const [iconPreview, setIconPreview] = useState<string | null>(themeToEdit?.logo || null);
  const [iconMiniPreview, setIconMiniPreview] = useState<string | null>(themeToEdit?.logoSmall || null);
  const {themeNotification}=useThemeNotification();
  useEffect(() => {
    // Update state whenever themeToEdit changes
    if (themeToEdit) {
      setNewThemeName(themeToEdit.title);
      setIsSelected(themeToEdit.isSelected);
      setIsDefault(themeToEdit.isDefault);
      setPrimaryColor(themeToEdit.theme.palette.primary.main);
      setSecondaryColor(themeToEdit.theme.palette.secondary.main);
      setIconPreview(themeToEdit.logo);
      setIconMiniPreview(themeToEdit.logoSmall);
    }
  }, [themeToEdit]);
  const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>, isMini: boolean = false) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (isMini) {
          setIconMiniPreview(reader.result as string);
        } else {
          setIconPreview(reader.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const clearDialogFields = () => {
    setNewThemeName('');
    setPrimaryColor('#000000');
    setSecondaryColor('#ffffff');
    setIconPreview(null);
    setIconMiniPreview(null);
  };

  const handleSave = () => {
    if (newThemeName && primaryColor && secondaryColor && iconPreview && iconMiniPreview) {
      const newTheme: IThemeConfig = {
        key: themeToEdit ? themeToEdit.key : sanitizeText(newThemeName.toLowerCase()),
        title: newThemeName,
        isDefault:isDefault,
        logo: iconPreview,
        isSelected:isSelected,
        logoSmall: iconMiniPreview,
        theme: {
          typography: { fontFamily: 'Open Sans' },
          shape: { borderRadius: 8 },
          palette: {
            primary: { main: primaryColor, contrastText: '#fff' },
            secondary: { main: secondaryColor, contrastText: '#000' }
          }
        }
      };

      onSave(newTheme);
      clearDialogFields();
    }else{
      themeNotification.fieldsareRequired();
    }
  };

  return (
    <>
      {open ? (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {themeToEdit ? t('edit') : t('new')}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
              <TextField
                label={t('name')}
                value={newThemeName}
                onChange={(e) => setNewThemeName(e.target.value)}
                fullWidth
                required
              />

              <Button variant='contained' component='label'>
                {t('uploadIcon')}
                <input type='file' hidden onChange={(e) => handleIconChange(e, false)} accept='image/*' />
              </Button>
              {iconPreview && (
                <img src={iconPreview} alt='Full Preview' style={{ width: '100px', marginTop: '10px' }} />
              )}

              <Button variant='contained' component='label'>
                {t('uploadMini')}
                <input type='file' hidden onChange={(e) => handleIconChange(e, true)} accept='image/*' />
              </Button>
              {iconMiniPreview && (
                <img src={iconMiniPreview} alt='Mini Preview' style={{ width: '50px', marginTop: '10px' }} />
              )}

              <TextField
                label={t('primaryColor')}
                type='color'
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
                fullWidth
                required
              />

              <TextField
                label={t('secondaryColor')}
                type='color'
                value={secondaryColor}
                onChange={(e) => setSecondaryColor(e.target.value)}
                fullWidth
                required
              />

              <Button onClick={handleSave} variant='contained' color='primary'>
                {themeToEdit ? t('updateTheme') : t('saveTheme')}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        ''
      )}
    </>
  );
};
