import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { IForm } from '@ccs-dip/common/types/formio-types';

export interface IFormDialogProps {
  open: boolean;
  formSchemas: Record<string, IForm> | undefined;
  selectedFormComponent: IForm | null;
  onClose: () => void;
  onSelectComponent: (componentName: string) => void;
  onReplaceComponent: () => void;
}

function FormDialog({
  open,
  formSchemas,
  selectedFormComponent,
  onClose,
  onSelectComponent,
  onReplaceComponent
}: IFormDialogProps) {
  const options = formSchemas ? Object.values(formSchemas) : [];

  return (
    <Dialog id='FormSelectionDialog' open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogContent>
        <DialogTitle>Form Selection Dialog</DialogTitle>
        <FormControl fullWidth>
          <Autocomplete
            id='SelectComponent'
            options={options}
            getOptionLabel={(option) => option.title ?? 'Unknown Title'}
            value={selectedFormComponent}
            onChange={(_event, newValue) => {
              if (newValue) {
                onSelectComponent(newValue.key);
              }
            }}
            renderInput={(params) => <TextField {...params} label='Select Component' />}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={onReplaceComponent} color='primary'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FormDialog;
