export type formioEventHandlerType = (data: any, instance: any, emitEventName: string) => void;

// To attach/append the event handlers on to formio instance object
export const attachEventHandler = (
  instance: any,
  eventName: string,
  emitEventName: string,
  action: formioEventHandlerType
) => {
  if (instance && !instance.hasEventHandler(eventName)) {
    instance.on(eventName, (data: any) => action(data, instance, emitEventName));
  }
};
