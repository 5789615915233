import { JSONItem, JSONObject } from '@ccs-dip/common/json/types';
import FormioService from 'entities/Formio/FormioService';
import { getRowComponentForDynamicContainer } from './generateComponentJson';
import { formioEventHandlerType } from './attachEventHandlerToFormioInstance';
import { Utils } from 'formiojs';

//===============================================
// private variables
//===============================================

const formio_service = new FormioService();

// For updating the key indices which are present under dynamic_component_container
// This is used while removing a row, because new row indices should be allocated.
const updateRowIndicesWithNewIndices = (components: Array<any>) => {
  if (components) {
    components.forEach((columnsComponent: any, index: number) => {
      columnsComponent.component.key = `columns_${index}`;
      columnsComponent.components.forEach((component: any) => {
        if (component.type === 'form') {
          component.component.key = `${component.component.form}_${index}`;
        } else {
          component.component.key = `remove_${index}`;
        }
      });
    });
  }
};

// For updating the values which are present under a dynamic_component_container,
// since new keys have been allocated for nested form component
const getUpdatedContainerValue = (instance: any, toRemoveFormComponentKey: string) => {
  const containerValue: JSONObject = instance.getValue();
  const updatedContainerValue: JSONObject = {};
  let index: number = 0;
  Object.keys(containerValue).forEach((key) => {
    const value: JSONItem = JSON.parse(JSON.stringify(containerValue[key]));
    if (value && key !== toRemoveFormComponentKey && typeof value !== 'boolean') {
      updatedContainerValue[`${key.substring(0, key.lastIndexOf('_'))}_${index}`] = value;
      index += 1;
    }
  });
  return updatedContainerValue;
};

// To trigger redraw and restoreComponentsContext functions of a component
const redrawAndRestore = (instance: any) => {
  instance.triggerRedraw();
  instance.restoreComponentsContext();
};

// For getting the nested form component's key which is present inside the columns component under the container
const getNestedFormKeyFormContainer = (instance: any, columns_component_key: string) => {
  // getting the row index where the columns component is located
  const index = +columns_component_key.substring(columns_component_key.lastIndexOf('_') + 1);
  const columnsInstance = instance.components[index];
  const nestedFormInstance = columnsInstance.components[0];
  return nestedFormInstance.key;
};

// For the field component's instance object which should be present under the same parent instance,
// as the add another button, which has add_dynamic_component property
const getFieldComponentInstance = (instance: any) => {
  const componentsInstances: Array<any> = instance.parent.components;
  return componentsInstances.filter(
    (componentsInstance) =>
      componentsInstance.component.type !== 'container' &&
      componentsInstance.component.properties &&
      componentsInstance.component.properties.dynamic_component_container ===
        instance.component.properties.add_dynamic_component
  )[0];
};

//===============================================
// public variables
//===============================================

// For getting the value and emitting event for adding the component
export const emitValueToAddDynamicComponent = (instance: any, emitEventName: string) => {
  const targetInstance = getFieldComponentInstance(instance);
  if (targetInstance) {
    // getting the path to fetch the value from the submission
    const value = targetInstance.getValue();
    if (typeof value === 'string' && value !== '') {
      instance.root.emit(emitEventName, { value: value, toRemove: false });
    }
  }
};

// For emitting event with columns component key for removing the component
export const emitValueToRemoveDynamicComponent = (instance: any) => {
  const key: string = instance.parent.key;
  // remove button's parent component, that is columns component and parent of it is container component
  const dynamic_component_container: string = instance.parent.parent.component.properties.dynamic_component_container;
  instance.root.emit(`dynamic_component_container-${dynamic_component_container}`, { value: key, toRemove: true });
};

// For handling the creation and deletion columns component (row)
export const addOrRemoveDynamicComponent = async (
  instance: any,
  isWizard: boolean,
  value: string,
  toRemove: boolean
) => {
  try {
    if (toRemove) {
      // To Rmove the columns component
      const toRemoveFormComponentKey: string = getNestedFormKeyFormContainer(instance, value);
      const updatedContainerValue = getUpdatedContainerValue(instance, toRemoveFormComponentKey);
      instance.removeComponentByKey(value); // here value contains the key of the columns component
      updateRowIndicesWithNewIndices(instance.components);
      instance.setValue(updatedContainerValue); // setting the value after all the changes
    } else {
      // To Add the columns component
      const isFormKey = /_[0-9]*$/.test(value); // here the value may contain form_key_{row}, because of setSubmission
      if (!isFormKey) {
        // preparing the value, so the it can be used for fetching the form's key
        value = isWizard ? `${instance.root.pages[instance.page].key}-${value}` : `${instance.root.form.name}-${value}`;
      }
      // contains form_key
      const key = isFormKey ? value.substring(0, value.lastIndexOf('_')) : await formio_service.getKey(value);
      if (key) {
        instance.addComponent(getRowComponentForDynamicContainer(key, instance.page ?? 0, instance.components.length));
      }
    }
    redrawAndRestore(instance);
  } catch (error) {
    console.log(error);
  }
};

// Event handler for dynamic_component_container events
export const dynamicComponentContainerEventHandler: formioEventHandlerType = (
  data: any,
  instance: any,
  emitEventName: string
) => {
  if (data) {
    const value = data.value ?? '';
    const toRemove = data.toRemove ?? false;
    const isWizard = instance.root.form.display === 'wizard';
    // The event listener works differently based on the type of instance
    if (isWizard) {
      addOrRemoveDynamicComponent(instance, isWizard, value, toRemove);
    } else {
      // emit's an event which is handled using logic's custom action
      instance.root.emit(emitEventName, { value, toRemove });
    }
  }
};

// For Adding the components based the container's value, which has been updated by the submission
export const beforeSetSubmissionForContainerEventHandler: formioEventHandlerType = (
  data: any,
  instance: any,
  _emitEventName: string
) => {
  const submission: JSONObject | undefined = data;
  if (submission) {
    const curValue: JSONObject = Utils.getValue(submission, instance.path);
    const prevValue = instance.getValue();
    Object.keys(curValue).forEach((key) => {
      if (prevValue[key] === undefined && typeof curValue[key] === 'object') {
        addOrRemoveDynamicComponent(instance, false, key, false);
      }
    });
  }
};
