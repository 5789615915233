import { ChangeEvent, useEffect, useState } from 'react';
import {
  GridRowModesModel,
  GridRowModes,
  GridEventListener,
  GridRowId,
  GridRowEditStopReasons
} from '@mui/x-data-grid-pro';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import TranslationService, { LanguageCode } from 'entities/Translation/TranslationService';
import { TranslationRow } from './TranslationRowMap';
import { ITranslationDataApi } from './ITranslationDataApi';
import { ITranslationEventApi } from './ITranslationEventApi';
import { clearFile, downloadFormAsJson, isValidLanguageTranslation, readFileAsJson, updateKey } from 'utils/Utils';

const useTranslationEvents = (rows: TranslationRow[], api: ITranslationDataApi) => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [open, setOpen] = useState(false);
  const [resolveDialog, setResolveDialog] = useState<((value: boolean) => void) | null>(null);
  const [overrideOption, setOverrideOption] = useState('override');
  const service = new TranslationService();
  const { showSnackBar } = useSnackBar();

  useEffect(() => {
    const storedOverrideOption = localStorage.getItem('overrideOption');
    if (storedOverrideOption) {
      setOverrideOption(storedOverrideOption);
    }
  }, []);

  //===============================================
  // local variables
  //===============================================

  const process = async (
    id: GridRowId,
    action: (lang: LanguageCode, row: TranslationRow) => Promise<void>,
    isDelete = false,
    updatedRow: any = null
  ) => {
    const mrows = updatedRow
      ? rows.map((itm: any) => {
          if (itm.id === updatedRow.id) {
            itm.key = updatedRow.key;
          }
          return itm;
        })
      : [...rows];
    const row = mrows.find((row) => row['id'] === id || row['key'] === id);
    if (!row) return;

    if (mrows.filter((r) => r['key'] === row.key).length > 1) {
      if (!isDelete) return showSnackBar('Translation key already exist', 'error');
    }

    if (row.key === '' && !isDelete) {
      return showSnackBar('Provide translation key', 'error');
    }

    try {
      await Promise.all(TranslationService.languages.map((lang) => action(lang, row)));
      showSnackBar('Saved successfully', 'success');
      api.getGrid();
    } catch (error: any) {
      showSnackBar(error.message, 'error');
      api.getGrid();
    }
    api.getGrid();
  };

  //===============================================
  // crud events
  //===============================================

  const onEdit = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    api.getGrid();
  };

  const onSave = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    api.getGrid();
  };

  const onDelete = (id: GridRowId) => () => {
    process(id, (lang, row) => api.delete(lang, row.key), true);
    api.getGrid();
  };

  const onCancel = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
    api.getGrid();
  };

  const onAdder = (id: GridRowId) => () => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit }
    }));
  };

  //===============================================
  // DataGrid events
  //===============================================

  const onDialogClose = (value: boolean) => {
    setOpen(false);
    if (resolveDialog) {
      resolveDialog(value);
    }
  };

  const onOverrideChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setOverrideOption(newValue);
    localStorage.setItem('overrideOption', newValue);
  };

  const processRowUpdate = (updatedRow: TranslationRow) => {
    if (updatedRow?.id) {
      process(
        updatedRow?.id,
        (lang, row) => {
          const modifiedRow = updateKey(row);
          const value = updatedRow[lang]?.custom;
          if (!modifiedRow.key) {
            modifiedRow.key = '$$empty';
          }
          return value !== undefined ? api.save(lang, modifiedRow.key, value) : api.delete(lang, modifiedRow.key);
        },
        false,
        updatedRow
      );
    } else {
      process(
        updatedRow.key,
        (lang, row) => {
          const value = updatedRow[lang]?.custom;
          return value !== undefined ? api.save(lang, row.key, value) : api.delete(lang, row.key);
        },
        false
      );
    }

    return { ...updatedRow };
  };

  const openOverrideDialog = () => {
    return new Promise<boolean>((resolve) => {
      setOpen(true);
      setResolveDialog(() => resolve);
    });
  };

  const getCurrentOption = () => localStorage.getItem('overrideOption') || 'override';

  const onFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (!selectedFile) {
      showSnackBar('No file selected', 'error');
      return;
    }

    const fileSize = selectedFile.size;
    const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);
    const maxSizeInMB = 10;
    if (parseFloat(fileSizeInMB) > maxSizeInMB) {
      showSnackBar('File size exceeds the limit', 'error');
      return;
    }

    if (!/.json$/gi.test(selectedFile.name)) {
      showSnackBar('Invalid file format, JSON required', 'error');
      return;
    }
    const confirmed = await openOverrideDialog();
    if (confirmed) {
      try {
        const translationData: any = await readFileAsJson(selectedFile);

        if (!isValidLanguageTranslation(translationData)) {
          showSnackBar('Invalid file structure', 'error');
          return;
        }

        for (const itm of translationData) {
          const currentOption = getCurrentOption();
          await service.save(itm.data.language, '$$bulk', itm.data.translation, currentOption);
        }
        clearFile(event);
        api.getGrid();
        showSnackBar('Translations uploaded successfully', 'success');
      } catch (error) {
        console.error('File upload error:', error);
        clearFile(event);
        showSnackBar('Failed to upload translations', 'error');
        api.getGrid();
      }
    }
  };

  const onRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const onRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const onExportAsJson = async (): Promise<void> => {
    try {
      const exportData = [];
      for (const itm of ['en', 'nl']) {
        const response = await service.download(itm as 'en' | 'nl');
        exportData.push(response);
      }
      downloadFormAsJson(exportData);
    } catch (error) {
      console.error('Error exporting form:', error);
    }
  };

  //===============================================
  // main
  //===============================================

  const events: ITranslationEventApi = {
    onCancel,
    onEdit,
    onDelete,
    onSave,
    processRowUpdate,
    onRowEditStop,
    onRowModesModelChange,
    onFileUpload,
    onExportAsJson,
    onAdder,
    onDialogClose,
    onOverrideChange
  };

  return { rowModesModel, events, open, overrideOption };
};

export default useTranslationEvents;
