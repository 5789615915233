import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OverviewDialogProps {
  open: boolean;
  title: string;
  description: string;
  url: string;
  onClose: () => void;
  onSave: () => void;
  onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUrlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  editMode: boolean;
}

const OverviewDialog: React.FC<OverviewDialogProps> = ({
  open,
  title,
  description,
  url,
  onClose,
  onSave,
  onTitleChange,
  onDescriptionChange,
  onUrlChange,
  editMode
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{editMode ? t('Edit Overview Item') : t('Add New Overview Item')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={`${t('Title')}*`}
          type="text"
          fullWidth
          variant="outlined"
          value={title}
          onChange={onTitleChange}
        />
        <TextField
          margin="dense"
          label={`${t('Description')}*`}
          type="text"
          fullWidth
          variant="outlined"
          value={description}
          onChange={onDescriptionChange}
        />
        <TextField
          margin="dense"
          label={`${t('URL')}*`}
          type="text"
          fullWidth
          variant="outlined"
          value={url}
          onChange={onUrlChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t('Cancel')}
        </Button>
        <Button onClick={onSave} color="primary">
          {editMode ? t('Update') : t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OverviewDialog;
