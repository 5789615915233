import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface SplitButtonProps {
  options: Array<string>;
  disabledOptions?: Array<string>;
  handleClick: (selectedOption: string) => void;
  handleMenuItemClick?: () => void;
  handleToggle?: () => void;
  handleClose?: () => void;
}

export default function SplitButton(props: SplitButtonProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  //   TODO: Handle logic for re-assignment of disabled options
  //   const [disabledOptions, setDisabledOptions] = useState<Array<string>>(props.disabledOptions ?? []);
  const disabledOptions = props.disabledOptions ?? [];

  const handleMenuItemClick = (_event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    if (props.handleMenuItemClick !== undefined) {
      props.handleMenuItemClick();
    }
    setSelectedIndex(index);
    props.handleClick(props.options[selectedIndex] ?? '');
    setOpen(false);
  };

  const handleToggle = () => {
    if (props.handleToggle !== undefined) {
      props.handleToggle();
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    if (props.handleClose !== undefined) {
      props.handleClose();
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        id='ButtonGroupMenu'
        variant='contained'
        ref={anchorRef}
        aria-label='Button group with a nested menu'>
        <Button
        color="secondary"
        sx={{color:(theme)=>theme.palette.primary.contrastText}}
          onClick={() => {
            props.handleClick(props.options[selectedIndex] ?? '');
            setSelectedIndex(0);
          }}>
          {t(props.options[selectedIndex] ?? '')}
        </Button>
        <Button
          id='SplitBtn'
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          color="secondary"
          onClick={handleToggle}>
          <ArrowDropDownIcon sx={{color:(theme)=>theme.palette.primary.contrastText}} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='SplitBtnMenu' autoFocusItem>
                  {props.options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={disabledOptions.includes(option)}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}>
                      {t(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
