import { combineReducers } from '@reduxjs/toolkit';
import counterReducer from './counter/counterSlice';
import briefViewDrawerReducer from './briefview/briefViewDrawerSlice';
import themeConfig from './theme/themeSlice';

export const rootReducer = combineReducers({
  counter: counterReducer,
  briefViewDrawer: briefViewDrawerReducer,
  themeConfig:themeConfig
});
