import { IForm, IFormInfo, ISaveFormResponse } from '@ccs-dip/common/types/formio-types';
import Server from 'entities/RCIPServerProxy';
import { handleError } from 'entities/RCIPErrorHandler';
import config from 'config/config';

//==============================================================
// private variables
//==============================================================

const getFormio = (key?: string): Promise<IForm> => {
  return Server.get(`/formio-form${key ? '/' + key : ''}`);
};

const getAllFormsAPI = (): Promise<IFormInfo[]> => {
  return Server.get(`/formio-form`);
};

const getFormioSrc = (key?: string): Promise<IForm> => {
  return fetch(`${config.formio.project_url}/${key}`).then((response) => {
    return response.json();
  });
};

//==============================================================
// Formio EntityService
//==============================================================

class FormioService {
  delete(key: string): Promise<void> {
    return Server.delete(`/formio-form/${key}`);
  }

  get(key?: string) {
    try {
      return getFormio(key).then((data: IForm) => {
        const formio = data;
        return formio;
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  getAllForm() {
    try {
      return getAllFormsAPI().then((data: IFormInfo[]) => {
        const formio: IFormInfo[] = data;
        return formio;
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  getForms(key?: string) {
    try {
      return getFormio(key)
        .then((data: IForm) => {
          if (!Object.keys(data).length) {
            return getFormioSrc(key).then((dataSrc: any) => {
              delete dataSrc.config;
              return dataSrc;
            });
          } else {
            return data;
          }
        })
        .catch((error) => {
          throw handleError(error);
        });
    } catch (error) {
      throw handleError(error);
    }
  }

  // For getting the form key using an entity value from the form
  getKey(value: string) {
    try {
      return Server.get(`/formio-form/key/${value}`).then((data: any): string | undefined => data['key']);
    } catch (error) {
      throw handleError(error);
    }
  }

  save(key: string, value: IForm) {
    return Server.post(`/formio-form/${key}`, value).then((response: ISaveFormResponse) => response);
  }
}

//==============================================================
// exports
//==============================================================

export default FormioService;
