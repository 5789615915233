import { Card, CardContent, Typography, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { cardCover, iconCover } from './ErrorCoverStyle';
import { useTranslation } from 'react-i18next';

const ErrorFallback = () => {
    const { t } = useTranslation();
  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      height="100vh"
    >
      <Card 
        sx={cardCover}
      >
        <CardContent>
          <ErrorOutlineIcon sx={iconCover} />
          <Typography variant="h5" component="div" gutterBottom>
            {t('boundaryPhrase')}
          </Typography>
          <Typography variant="body1" color="textSecondary">
          {t('boundaryCaption')}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ErrorFallback;
