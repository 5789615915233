import Server from 'entities/RCIPServerProxy';
import { IAppConfig } from '@ccs-dip/common/types/app-config';

//==============================================================
// App-Config EntityService
//==============================================================

class AppConfigService {
  getPath() {
    return `/open-config/`;
  }

  get() {
    return Server.get(this.getPath()).then((data: IAppConfig) => data);
  }

  save(value: IAppConfig) {
    return Server.post(`${this.getPath()}`, value).then((response: IAppConfig) => response);
  }
}

//==============================================================
// exports
//==============================================================

export default AppConfigService;
