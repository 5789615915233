import React, { useState, useEffect } from 'react';
import { Box, IconButton, Menu, MenuItem, TextField, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import InsuranceGradIcon from 'component/atoms/Icons/InsuranceGradIcon';
import { generateRandomId } from 'utils/Utils';
import { styles } from '../../molecules/InsuranceOverview/InsuranceOverviewStyles';
import { useTranslation } from 'react-i18next';
import OverviewDialog from '../../molecules/InsuranceOverview/InsuranceOverviewDialog';
import SearchIcon from '@mui/icons-material/Search';
import AppConfigService from 'entities/AppConfig/AppConfigService';
import { IAppConfig, ITileInfo } from '@ccs-dip/common/types/app-config';
import useInsuranceNotification from 'component/molecules/InsuranceOverview/InsuranceNotifications';
import InsuranceOverviewGrid from '../../molecules/InsuranceOverview/InsuranceOverviewGrid';

const InsuranceOverview = () => {
  const [overviewList, setOverviewList] = useState<ITileInfo[]>([]);
  const [appConfig, setAppConfig] = useState<IAppConfig | null>(null);
  const [filteredOverviewList, setFilteredOverviewList] = useState<ITileInfo[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newUrl, setNewUrl] = useState('');
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const { t } = useTranslation();
  const { insuranceOverviewNotification } = useInsuranceNotification();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog

  useEffect(() => {
    const fetchOverviewData = async () => {
      const appConfigService = new AppConfigService();
      const response = await appConfigService.get();
      if (Object.keys(response).length) {
        setAppConfig(response);
        setOverviewList(response.tileconfigs);
        setFilteredOverviewList(response.tileconfigs); // Initialize filtered list
      }
    };
    fetchOverviewData();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredOverviewList(
      overviewList.filter(
        (item) =>
          item.title.toLowerCase().includes(query) || item.description.toLowerCase().includes(query)
      )
    );
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setFilteredOverviewList(overviewList); // Reset the filtered list to show all items
  };

  const handleAddClick = () => {
    setEditIndex(null);
    setNewTitle('');
    setNewDescription('');
    setNewUrl('');
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditIndex(null);
    setNewTitle('');
    setNewDescription('');
    setNewUrl('');
  };

  const saveOverviewData = async (updatedList: ITileInfo[]) => {
    if (!appConfig) return;

    try {
      const appConfigService = new AppConfigService();
      const updatedAppConfig = {
        ...appConfig,
        tileconfigs: updatedList,
      };
      await appConfigService.save(updatedAppConfig);
      setAppConfig(updatedAppConfig);
      insuranceOverviewNotification.savedSuccessfully();
    } catch (error) {
      insuranceOverviewNotification.saveFailed();
    }
  };

  const deleteOverviewData = async (key: string) => {
    if (!appConfig) return;

    try {
      const appConfigService = new AppConfigService();
      const updatedList = overviewList.filter((item) => item.key !== key);
      const updatedAppConfig = {
        ...appConfig,
        tileconfigs: updatedList,
      };
      await appConfigService.save(updatedAppConfig);
      setOverviewList(updatedList);
      setFilteredOverviewList(updatedList);
      setAppConfig(updatedAppConfig);
      insuranceOverviewNotification.savedSuccessfully();
    } catch (error) {
      insuranceOverviewNotification.saveFailed();
    }
  };

  const handleAddOrUpdateOverviewItem = () => {
    if (newTitle && newDescription && newUrl) {
      const updatedList = [...overviewList];
      const newItem = {
        key: editIndex ? updatedList[editIndex]?.key || '' : generateRandomId(),
        title: newTitle,
        description: newDescription,
        url: newUrl,
      };

      if (editIndex !== null) {
        updatedList[editIndex] = newItem;
      } else {
        updatedList.push(newItem);
      }
      saveOverviewData(updatedList);
      setOverviewList(updatedList);

      // Filter the updated list based on the current search query
      const filteredList = updatedList.filter(
        (item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredOverviewList(filteredList);

      handleDialogClose();
    } else {
      insuranceOverviewNotification.formValidationError();
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItemIndex(null);
  };

  const handleEdit = () => {
    if (selectedItemIndex !== null) {
      const item: ITileInfo | undefined = overviewList[selectedItemIndex];
      if (item) {
        setNewTitle(item.title);
        setNewDescription(item.description);
        setNewUrl(item.url);
      }
      setEditIndex(selectedItemIndex);
      setDialogOpen(true);
    }
    handleMenuClose();
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true); // Open delete confirmation dialog
  };

  const confirmDelete = () => {
    if (selectedItemIndex !== null) {
      const selectedItem = overviewList[selectedItemIndex];
      if (selectedItem) deleteOverviewData(selectedItem.key);
    }
    setDeleteDialogOpen(false); // Close the confirmation dialog
    handleMenuClose(); // Ensure the menu is closed after deletion
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false); // Close the confirmation dialog without deleting
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <InsuranceGradIcon />
        <Typography variant='h6' sx={styles.headerTitle} color='text.primary'>
          {t('policy')}
        </Typography>
        <Box sx={{ ml: 'auto', display: 'flex', gap: 1, alignItems: 'center' }}>
          <IconButton onClick={() => setShowSearchBar(!showSearchBar)} sx={{ cursor: 'pointer' }} color='secondary'>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      {showSearchBar && (
        <TextField
          variant='outlined'
          sx={{ p: 2 }}
          fullWidth
          size='small'
          margin='normal'
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={handleClearSearch}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}

      <Box sx={{ maxHeight: '550px', overflowY: 'auto' }}>
        {/* Scrollable container */}
        {filteredOverviewList.length > 0 && (
          <InsuranceOverviewGrid 
          handleAddClick={handleAddClick}
            filteredOverviewList={filteredOverviewList} 
            handleMenuOpen={handleMenuOpen} 
          />
        )}
      </Box>

      <OverviewDialog
        open={dialogOpen}
        title={newTitle}
        description={newDescription}
        url={newUrl}
        onClose={handleDialogClose}
        onSave={handleAddOrUpdateOverviewItem}
        onTitleChange={(e) => setNewTitle(e.target.value)}
        onDescriptionChange={(e) => setNewDescription(e.target.value)}
        onUrlChange={(e) => setNewUrl(e.target.value)}
        editMode={editIndex !== null}
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleEdit}>{t('edit')}</MenuItem>
        <MenuItem onClick={handleDelete}>{t('delete')}</MenuItem>
      </Menu>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={cancelDelete}>
        <DialogTitle>{t('confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('formDelete')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color='primary'>
            {t('cancel')}
          </Button>
          <Button onClick={confirmDelete} color='secondary'>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InsuranceOverview;
