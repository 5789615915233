import { Formio, Utils } from 'formiojs';
import { IFormioSubmissionRequest, IFormioSubmissionResponse } from '@ccs-dip/common/types/formio-types';
import { tryInitializeFieldSelectElement } from './tryInitializeFieldSelectElement';
import config from 'config/config';
import { attachEventHandler } from './attachEventHandlerToFormioInstance';
import {
  valueChangeEventHandler,
  beforeSetSubmissionForNestedFormEventHandler,
  replaceFormEventHandler,
  replaceFormByValue
} from './replaceFormFuctions';
import {
  emitValueToAddDynamicComponent,
  addOrRemoveDynamicComponent,
  beforeSetSubmissionForContainerEventHandler,
  dynamicComponentContainerEventHandler,
  emitValueToRemoveDynamicComponent
} from './dynamicComponentContainerFunctions';

//==============================================================
// extend global interfaces
//==============================================================

declare global {
  interface Window {
    formio_support: FormioRuntimeSupport;
  }
}

//==============================================================
// private variables
//==============================================================

const send_to_server = (data: IFormioSubmissionRequest): Promise<IFormioSubmissionResponse> => {
  const url = new URL('/api/formio-submission', config.server_url).href;

  return Formio.makeStaticRequest(url, 'POST', data).catch(() => {
    // swallow error, it will be displayed by the ErrorFetchPlugin
  });
};

//==============================================================
// class FormioRuntimeSupport
//==============================================================

export class FormioRuntimeSupport {
  send(form: any, submission: any, component: any) {
    if (!form.root.checkValidity(null, true, null, true)) {
      form.root.emit('error');
      return;
    }
    const tags = component.tags ?? [];
    const properties = component.properties ?? {};
    const data = {
      form: {
        name: form._form.name
      },
      component: {
        tags,
        properties
      },
      submission
    };

    send_to_server(data).then((response: IFormioSubmissionResponse) => {
      const messages = response?.messages ?? [];
      const response_submission = response?.submission;
      messages.forEach((message) => {
        const component = Utils.getComponent(form.components, message.ref, false);
        component.setCustomValidity(
          {
            level: 'error',
            message: message.value
          },
          true,
          true
        );
        console.log('DEBUG set error', component);
      });
      if (response_submission !== undefined) {
        form.root.setSubmission(Utils._.merge(submission, response_submission));
        form.root.emit('Success');
      }
    });
  }

  apiFieldCustomDefaultValue() {
    tryInitializeFieldSelectElement();
  }

  // To capture value change
  replaceFormFieldCustomDefaultValue(instance: any, emitEventName: string) {
    attachEventHandler(instance, 'change', emitEventName, valueChangeEventHandler);
    attachEventHandler(instance, 'beforeSetSubmission', emitEventName, beforeSetSubmissionForNestedFormEventHandler);
  }

  // To change form from formio logic
  replaceFormNestedFormCustomAction(instance: any, value: string) {
    replaceFormByValue(instance, value);
  }

  // To change form from formio event or trigger logic to handle form replacement
  replaceFormNestedFormCustomDefaultValue(instance: any, eventName: string) {
    attachEventHandler(instance, eventName, `${instance.key}-${eventName}`, replaceFormEventHandler);
  }

  // To emit the value which will add the dynamically generated components
  dynamicComponentAddButtonCustomAction(instance: any, emitEventName: string) {
    emitValueToAddDynamicComponent(instance, emitEventName);
  }

  // To emit the value which will remove the dynamically generated components
  dynamicComponentRemoveButtonCustomAction(instance: any) {
    emitValueToRemoveDynamicComponent(instance);
  }

  // To add or remove dynamic components from formio logic
  dynamicComponentContainerCustomAction(instance: any, { value, toRemove }: { value: string; toRemove: boolean }) {
    addOrRemoveDynamicComponent(instance, false, value, toRemove);
  }

  // To add or remove dynamic components from formio event or trigger logic to  that handles it
  dynamicComponentContainerCustomDefaultValue(instance: any, eventName: string) {
    attachEventHandler(instance, eventName, `${instance.key}-${eventName}`, dynamicComponentContainerEventHandler);
    attachEventHandler(instance, 'beforeSetSubmission', eventName, beforeSetSubmissionForContainerEventHandler);
  }
}
