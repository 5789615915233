/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Button, CircularProgress, FormControl, ListItemButton, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { InputLable } from '../InputLabel/InputLabel';
import { keyGenerator } from '../../../utils/Utils';
import SearchService from '../../../entities/Search/SearchService';
import { ISearchResult } from '../../../entities/Search/ISearchResult';
import IconInsuranceSolid from 'component/atoms/Icons/IconInsuranceSolid';
//import IconInsurance from 'component/atoms/Icons/IconInsuranceSolid';
import IconHouse from 'component/atoms/Icons/IconHouse';
import IconInvoice from 'component/atoms/Icons/IconInvoice';
import IconPerson from 'component/atoms/Icons/IconPerson';
import IconRemove from 'component/atoms/Icons/IconRemove';
import {
  GroupHeader,
  MenuItemsIcon,
  GroupItems,
  formControlWrapper,
  autocompleteWrapper,
  partyName,
  partyNumber,
  //partyDate,
  noResults,
  searchResults,
  noOptions
} from './SmartsearchStyles';
import { setBriefView, setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';
import PartyBriefView from 'component/pages/Party/PartyBriefView';
import { useDispatch } from 'react-redux';
import PolicyBriefView from 'component/pages/Policy/PolicyBriefView';
import { ClaimBriefView } from 'component/pages/Claim/ClaimBriefView';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import OfferBriefView from 'component/pages/Offer/OfferBriefView';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import ButtonMenu from 'component/pages/Action/AddOptions';
import './SmartSearch.css';

const randomKey = keyGenerator();

interface ISmartsearch {
  searchVal?: string;
  handleInputChange: Function;
  handleIconClick?: Function;
  label?: string;
  clearInput: Function;
}

export default function Smartsearch(props: ISmartsearch) {
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const service = new SearchService();
  const [open, setOpen] = useState(false);
  //const [create, setCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const search = JSON.parse(localStorage.getItem('recentSearch') as any) || [];
  const [recentSearch, setRecentSearch] = useState<any>(search);
  const [result, setResult] = useState<ISearchResult[]>([]);
  const [highlightedOption, setHighlightedOption] = useState<ISearchResult | null>(null);
  const dispatch = useDispatch();

  const sizeRecentSearch = -5;
  const emptyRow: ISearchResult = {
    CommercialName: '$$empty',
    Domain: 'searchResult',
    Characteristics: '$$empty',
    Description: '$$empty',
    PartyNumber: 0,
    Key: '0',
    DomainOrg: '$$empty'
  };

  function handleRecentSearch(option: ISearchResult) {
    if (option.Domain !== 'ARecent Search') {
      option.DomainOrg = option.Domain;
      option.Domain = 'ARecent Search';
    }
    const temp = [option, ...recentSearch].slice(sizeRecentSearch);
    const arrayUniqueByKey = [...new Map(temp.map((item) => [item['Characteristics'], item])).values()];
    setRecentSearch([...arrayUniqueByKey]);
  }

  function handleNavigate(option: ISearchResult, tab: number) {
    setOpen(false);
    handleRecentSearch(option);
    switch (tab) {
      case 0:
        dispatch(setBriefView(<PartyBriefView partyNumber={String(option.PartyNumber)} />));
        break;
      case 1:
        dispatch(setBriefView(<OfferBriefView policyNumber={String(option.Key.split('?')[1])} />));
        break;
      case 2:
        dispatch(setBriefView(<PolicyBriefView policyNumber={String(option.Key.split('?')[1])} policyVersion={'P'} />));
        break;
      case 3:
        dispatch(setBriefView(<ClaimBriefView claimNumber={String(option.Key)} />));
        break;
      case 4:
        dispatch(setBriefView(<PolicyBriefView policyNumber={String(option.Key.split('?')[1])} policyVersion={'W'} />));
        break;
      default:
        dispatch(setBriefView(<PartyBriefView partyNumber={String(option.PartyNumber)} />));
        break;
    }
    dispatch(setOpenBriefView(true));
  }

  function fetchSearchResult(search: string) {
    setLoading(true);
    setOpen(false);

    if (!search) {
      setLoading(false);
      setOpen(false);
      setResult([]);
      return;
    }

    service
      .search(search, 'All', false, true)
      .then((resp: ISearchResult[]) => {
        const flattenedResults = resp.flat();
        setLoading(false);
        setOpen(true);
        if (recentSearch.length) {
          if (!!flattenedResults.length) {
            const temp = [...flattenedResults]
              .filter((itm) => itm.Domain !== '$$empty')
              .filter((itm) => !itm.DomainOrg);
            setResult([...temp]);
          } else {
            setResult([
              emptyRow
            ]);
          }
        } else {
          setResult([
            ...flattenedResults
          ]);
        }
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        return [];
      });
  }

  useEffect(() => {
    fetchSearchResult(props.searchVal || '');
  }, [props.searchVal]);

  const validateMenuOpen = (value?: any) => {
    if (recentSearch.length && (!props.searchVal || !value)) {
      ManipulateRecentSearch();
      setOpen(true);
    } else {
      setOpen(!!props.searchVal);
    }
  };
  const validateMenuOnFocus = (value?: any) => {
    if (recentSearch.length && !value) {
      ManipulateRecentSearch();
      setOpen(true);
    } else {
      setOpen(!!value);
    }
  };
  const ManipulateRecentSearch = () => {
    localStorage.setItem('recentSearch', JSON.stringify(recentSearch, null));
    const temp = [...result].filter((itm) => !itm.DomainOrg);
    if (!props.searchVal) {
      setResult([...recentSearch]);
    } else {
      setResult([...temp]);
    }
  };

  useEffect(() => {
    ManipulateRecentSearch();
  }, [recentSearch]);

  return (
    <>
      <FormControl className='formCloak' sx={formControlWrapper} variant='standard'>
        {props.label && (
          <InputLable shrink htmlFor={`${randomKey}MuiInputfield`} sx={{ typography: 'body2' }}>
            {props.label}
          </InputLable>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            autoComplete={true}
            openOnFocus={false}
            noOptionsText={<Box sx={noOptions}>{t('no_results')}</Box>}
            classes={{
              popper: 'custom-popper-class'
            }}
            filterOptions={(x) => {
              return x;
            }}
            id='smart-search'
            options={result.sort((a, b) => -b?.Domain?.localeCompare(a?.Domain))}
            groupBy={(option) => option.Domain}
            open={open}
            clearOnBlur={false}
            onOpen={() => {
              validateMenuOpen();
            }}
            onClose={() => setOpen(false)}
            getOptionLabel={(option) => option.CommercialName}
            sx={autocompleteWrapper}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                value={props.searchVal}
                onFocus={() => {
                  validateMenuOpen();
                }}
                onChange={(e) => {
                  validateMenuOnFocus(e.target.value);
                  props.handleInputChange(e.target.value as string);
                }}
                onKeyUp={(e: any) => {
                  validateMenuOnFocus(e.target.value);
                }}
                onKeyDown={(e: any) => {
                  validateMenuOnFocus(e.target.value);
                }}
                size='small'
                placeholder={t('smartSearchPlaceholder')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading && <CircularProgress color='inherit' size={20} />}
                      <Box
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpen(!open);
                        }}>
                        {params.InputProps.endAdornment}
                      </Box>
                    </React.Fragment>
                  ),
                  startAdornment: (
                    <React.Fragment>
                      <SearchIcon />
                    </React.Fragment>
                  )
                }}
              />
            )}
            onHighlightChange={(_event: any, option) => {
              setHighlightedOption(option);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && highlightedOption) {
                if (highlightedOption.Domain !== 'Recent Search')
                  handleNavigate(highlightedOption, getTabByDomain(highlightedOption.Domain));
                else handleNavigate(highlightedOption, getTabByDomain(highlightedOption?.DomainOrg as string));
              }
            }}
            renderOption={(props, option) => {
              return option.Domain === 'searchResult' ? renderEmptyOption(props) : renderOption(props, option);
            }}
            renderGroup={(params) => {
              return renderGroup(params);
            }}
          />
          {props.searchVal && props.searchVal.length ? (
            <Button
              onClick={() => {
                props.clearInput();
              }}
              sx={{ position: 'absolute', right: '50px' }}>
              <IconRemove></IconRemove>
            </Button>
          ) : (
            ''
          )}
        </Box>
      </FormControl>
      <ButtonMenu></ButtonMenu>
    </>
  );

  function renderEmptyOption(props: any) {
    return (
      <ListItemButton disabled={true} id={'Empty'} {...props}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={noResults}>{t('no_results')}</Box>
        </Box>
      </ListItemButton>
    );
  }

  function renderOption(props: any, option: ISearchResult) {
    const optionId = `${option.DomainOrg ? option.DomainOrg : option.Domain}_${option.Key}`;
    let formattedResult = '';

    let partyKeyParts: string[];
    let nameParts: string[];
    let policyKeyParts: string[];
    let policyNumber: string;
    let policyCharacteristicParts: string[];
    let companyPolicyNumber: string;
    let characteristic: string;
    let companyClaimNumber: string;
    let claimNumber: string;
    let claimCharacteristic: string;

    switch (option.DomainOrg ? option.DomainOrg : option.Domain) {
      case 'Party':
        partyKeyParts = option.Key.split('?');
        nameParts = partyKeyParts[1]?.split(',') ?? [];
        formattedResult = `${nameParts[0]}, ${nameParts[1]}, ${option.Characteristics}`;
        break;
      case 'Policy':
      case 'Offer':
      case 'WPolicy':
        policyKeyParts = option.Key.split('?');
        policyNumber = policyKeyParts[1] ?? '';
        policyCharacteristicParts = option.Characteristics?.split(',') ?? '';
        companyPolicyNumber = policyCharacteristicParts[0] ?? '';
        characteristic = policyCharacteristicParts[policyCharacteristicParts.length - 1]?.trim() ?? '';
        formattedResult = `${companyPolicyNumber}, ${policyNumber}, ${characteristic}`;
        break;

      case 'Claim':
        companyClaimNumber = option.Characteristics?.split(',')[0]?.trim() ?? '';
        claimNumber = option.Key;
        claimCharacteristic = option.Characteristics.split(',')[1]?.trim() ?? '';
        formattedResult = `${companyClaimNumber}, ${claimNumber}, ${claimCharacteristic}`;
        break;

      default:
        // Handle other cases if needed
        break;
    }

    return (
      <ListItemButton
        id={optionId}
        {...props}
        onClick={() => handleNavigate(option, getTabByDomain(option.DomainOrg || option.Domain))}>
        <Box sx={{ display: 'flex' }}>
          <Tooltip title={t(option.DomainOrg ? option.DomainOrg : option.Domain)}>
            <MenuItemsIcon sx={{ width: '45px', background: 'transparent' }}>
              {getIconByDomain(option.DomainOrg ? option.DomainOrg : option.Domain)}
            </MenuItemsIcon>
          </Tooltip>
          <Box sx={partyName}>{option.PartyNumber + ','}</Box>
          <Box sx={partyNumber}>{formattedResult}</Box>
        </Box>
      </ListItemButton>
    );
  }

  function renderGroup(params: any) {
    const isSearchResult = /searchresult/gi.test(params.group);
    const isRecent = /recent/gi.test(params.group);
    const groupHeaderId = params.group;
    const groupHeaderText = isRecent ? t('recentsearch') : t(params.group.trim().toLowerCase());

    return (
      <li key={params.key}>
        {!isSearchResult && <GroupHeader id={groupHeaderId}>{groupHeaderText}</GroupHeader>}
        <GroupItems>
          {isSearchResult ? (
            <ListItemButton sx={searchResults} disabled id='Empty' {...props}>
              {t('no_results')}
            </ListItemButton>
          ) : (
            params.children
          )}
        </GroupItems>
      </li>
    );
  }

  function getTabByDomain(domain: string) {
    switch (domain) {
      case 'Party':
        return 0;
      case 'Offer':
        return 1;
      case 'Policy':
        return 2;
      case 'Claim':
        return 3;
      case 'WPolicy':
        return 4;
      default:
        return 0;
    }
  }

  function getIconByDomain(domain: string) {
    switch (domain) {
      case 'Party':
        return <IconPerson />;
      case 'Offer':
        return <IconInvoice />;
      case 'Policy':
        return <IconInsuranceSolid />;
      case 'Claim':
        return <IconHouse />;
      case 'WPolicy':
        return <IconInsuranceSolid />;
      default:
        return <IconInsuranceSolid />;
    }
  }
}
