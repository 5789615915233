import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { cardStyles, cardContainerStyle } from './SettingsThemeStyles';
import { useTranslation } from 'react-i18next';

interface AddThemeCardProps {
  selectedTheme?: string;
  onAddTheme: () => void;
}

export const AddThemeCard: React.FC<AddThemeCardProps> = ({ selectedTheme, onAddTheme }) => {
  const { t } = useTranslation();

  return (
    <Box sx={cardContainerStyle}>
      <Card
        onClick={onAddTheme}
        sx={{
          ...cardStyles(selectedTheme||'', 'addTheme'),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        variant='outlined'>
        <AddIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
        <Typography sx={{ p: 1 }} variant='subtitle1'>
          {t('add')}
        </Typography>
      </Card>
    </Box>
  );
};
