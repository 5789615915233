import { FieldInfo } from '@ccs-dip/common/FieldInfo';
import { IComponent, IForm, IFormInfo } from '@ccs-dip/common/types/formio-types';

export const FORMCONSTANTS = {
  FORM_DISPLAY_FORM: 'form',
  FORM_DISPLAY_WIZARD: 'wizard',
  FORM_PATH_PREFIX: '/formrenderer/'
};

export const createEmptyForm = (title: string, formBuilderState: any): IForm => {
  const name = sanitizeText(title);
  const date = new Date().toISOString();

  return {
    key: name,
    id: '',
    title,
    name,
    productId: name,
    path: `${FORMCONSTANTS.FORM_PATH_PREFIX}${name}`,
    type: FORMCONSTANTS.FORM_DISPLAY_FORM,
    display:
      formBuilderState.isEditingForm || formBuilderState.isNewForm
        ? FORMCONSTANTS.FORM_DISPLAY_FORM
        : FORMCONSTANTS.FORM_DISPLAY_WIZARD,
    created: date,
    modified: date,
    savedOption: 'draft',
    components: []
  };
};

export const checkFieldValues=(form:IForm)=>{
  if(!form.productId){
    form.productId=form.name;
  }
  return form;
}

export const isValidResponse = (response: any): boolean => response && Object.keys(response).length > 0;

export const isFormValid = (form: IForm): string[] => {
  const errorMessages: string[] = [];
  if (!form.title) {
    errorMessages.push('Title is required');
  }
  if (!form.productId  && !form.name) {
    errorMessages.push('Product ID is required');
  }
  if (form.components.length === 0) {
    errorMessages.push('Components cannot be empty');
  }
  return errorMessages;
};

export const filterNonWizardForms = (forms: IFormInfo[]): Record<string, IForm> => {
  return forms.reduce((acc, form) => {
    if (form.display !== 'wizard') {
      const formioSchema: IForm = {
        key: form.key,
        title: form.title,
        display: form.display,
        name: form.display,
        productId: form.productId,
        components: [],
        id: '',
        path: '',
        type: '',
        created: '',
        modified: '',
        savedOption: 'draft'
      };
      acc[form.name] = { ...formioSchema };
    }
    return acc;
  }, {} as Record<string, IForm>);
};

export const containsDummyComponent = (components: IComponent[]): boolean =>
  components.some((component) => {
    const isDummy = component.key === 'DUMMY_FORM';
    const hasNestedDummy = component.components && containsDummyComponent(component.components);
    return isDummy || hasNestedDummy;
  });

export const removeDummyComponent = (
  components: IComponent[],
  isopen?: boolean,
  selectedFormComponent?: IForm
): IComponent[] => {
  return components.reduce((acc: IComponent[], component) => {
    if (component.key === 'DUMMY_FORM') {
      if (selectedFormComponent?.components && isopen) {
        acc.push(...selectedFormComponent.components);
      }
    } else {
      if (component.components && component.components.length > 0) {
        component.components = removeDummyComponent(component.components, isopen, selectedFormComponent);
      }
      acc.push(component);
    }
    return acc;
  }, []);
};

export const containsDummyField = (components: IComponent[]): boolean =>
  components.some((component) => {
    const isDummy = component.key === 'DUMMY_FIELD';
    const hasNestedDummy = component.components && containsDummyField(component.components);
    return isDummy || hasNestedDummy;
  });

export const removeDummyField = (
  components: IComponent[],
  isopen?: boolean,
  selectedField?: IComponent
): IComponent[] => {
  return components.reduce((acc: IComponent[], component) => {
    if (component.key === 'DUMMY_FIELD') {
      if (selectedField && isopen) {
        acc.push(selectedField);
      }
    } else {
      if (component.components && component.components.length > 0) {
        component.components = removeDummyField(component.components, isopen, selectedField);
      }
      acc.push(component);
    }
    return acc;
  }, []);
};

export const sanitizeText = (text: string): string => text.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

export const formatEnumForFormio = (fieldInfo: FieldInfo): { values: { label: string; value: string }[] } => {
  return {
    values: fieldInfo?.enum?.map((e) => ({ label: fieldInfo.name + '.' + e, value: e })) || []
  };
};

export const mapFieldInfoToComponent = async (fieldInfo: FieldInfo): Promise<IComponent> => {
  let componentType: string;
  let minLength = fieldInfo.minLength || '';
  let maxLength = fieldInfo.maxLength || '';
  let selectData: any;

  if (fieldInfo.type === 'string' && fieldInfo.enum && fieldInfo.enum.length > 0) {
    componentType = 'select';
    selectData = formatEnumForFormio(fieldInfo);
  } else {
    switch (fieldInfo.format) {
      case 'textarea':
      case 'UUID':
        componentType = 'textarea';
        minLength = minLength || '0';
        maxLength = maxLength || '255';
        break;
      case 'email':
        componentType = 'email';
        break;
      case 'url':
        componentType = 'url';
        break;
      case 'phoneNumber':
        componentType = 'phoneNumber';
        break;
      case 'int32':
        componentType = 'number';
        break;
      case 'date-time':
        componentType = 'datetime';
        break;
      case 'date':
        componentType = 'datetime';
        break;
      case 'time':
        componentType = 'time';
        break;
      case 'currency':
        componentType = 'currency';
        break;
      default:
        switch (fieldInfo.type) {
          case 'string':
          case 'char':
            componentType = fieldInfo.propertyNames.includes('textarea') ? 'textarea' : 'textfield';
            minLength = minLength || (componentType === 'textfield' ? '0' : '0');
            maxLength = maxLength || (componentType === 'textfield' ? '100' : '255');
            break;
          case 'bool':
          case 'boolean':
            componentType = 'checkbox';
            break;
          case 'integer':
          case 'number':
            componentType = 'number';
            break;
          case 'password':
            componentType = 'password';
            break;
          case 'tags':
            componentType = 'tags';
            break;
          case 'select':
            componentType = 'select';
            break;
          default:
            componentType = 'textfield';
            break;
        }
        break;
    }
  }

  const baseComponent = {
    type: componentType,
    key: fieldInfo.path,
    fieldSelector: fieldInfo.path,
    title: fieldInfo.name,
    input: true,
    label: fieldInfo.name,
    validate: {
      custom: fieldInfo.format,
      required: fieldInfo.required,
      minLength: minLength,
      maxLength: maxLength
    },
    data: selectData
  };

  if (componentType === 'datetime') {
    return {
      ...baseComponent,
      labelPosition: 'left-left',
      format: 'yyyy-MM-dd',
      datePicker: {
        disableWeekends: false,
        disableWeekdays: false,
        minDate: "moment().subtract(120,'years');",
        maxDate: 'moment()'
      },
      enableTime: false,
      persistent: 'client-only',
      clearOnHide: false,
      calculateValue: "if (value !== undefined && value !== null)\n  value = value.split('T')[0];",
      validate: {
        required: true,
        custom:
          'if (input != "") {\n    const minDate = moment().subtract(18, \'years\').format("YYYY-MM-DD");\n    valid = (input <= minDate) ? true : \'Age must be at least 18 years.\';\n}',
        minLength: '',
        maxLength: ''
      },
      enableMinDateInput: true,
      enableMaxDateInput: true,
      widget: {
        type: 'calendar',
        displayInTimezone: 'viewer',
        locale: 'en',
        useLocaleSettings: false,
        allowInput: true,
        mode: 'single',
        enableTime: false,
        noCalendar: false,
        format: 'yyyy-MM-dd',
        hourIncrement: 1,
        minuteIncrement: 1,
        time_24hr: false,
        minDate: "moment().subtract(120,'years');",
        disableWeekends: false,
        disableWeekdays: false,
        maxDate: 'moment()'
      }
    };
  }

  return baseComponent;
};
