import { useTheme } from '@mui/material/styles';
const IconCarside = () =>{ 
    const theme = useTheme();
    return(
  <svg xmlns="http://www.w3.org/2000/svg" width='25px' height='25px' viewBox="0 0 640 512">
     <defs>
      <linearGradient id="IconCarside" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor:  theme.palette.secondary.main, stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <path fill="url(#IconCarside)" d="M171.3 96H224v96H111.3l30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192V96h81.2c9.7 0 18.9 4.4 25 12l67.2 84H272zm256.2 1-100-125c-18.2-22.8-45.8-36-75-36H171.3C132 32 96.7 55.9 82.2 92.3L40.6 196.4C16.8 205.8 0 228.9 0 256v112c0 17.7 14.3 32 32 32h33.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80h130.7c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80h33.3c17.7 0 32-14.3 32-32v-48c0-65.2-48.8-119-111.8-127zm-93.5 175a48 48 0 1 1 90.5 32 48 48 0 1 1-90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
  </svg>
)}
export default IconCarside;