import { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IComponent, IForm } from '@ccs-dip/common/types/formio-types';
import {
  createEmptyForm,
  isFormValid,
  filterNonWizardForms,
  removeDummyComponent,
  containsDummyComponent,
  containsDummyField,
  removeDummyField,
  mapFieldInfoToComponent,
  checkFieldValues
} from './FormBuilderUtils';
import FormDialog from './FormDialog';
import FormBuilderControls from './FormBuilderControls';
import FormFields from './FormFields';
import FormioService from 'entities/Formio/FormioService';
import FormBuilderWrapper from './FormBuilderWrapper';
import FieldDialog from './FieldDialog';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import FormioReportOverview from '../FormioReport/FormioReportOverview';
import { IMessage } from '@ccs-dip/common/types/message';
import { FieldInfo } from '@ccs-dip/common/FieldInfo';

export interface ICustomFormBuilderProps {
  isNewForm?: string;
  row: {
    title?: string;
    key?: string;
  };
  handleClose: () => void;
  formBuilderState: {
    isEditingForm: boolean;
    isNewForm: boolean;
    isWizardEditing: boolean;
    isNewWizard: boolean;
  };
}

const CustomFormBuilder = ({ row, formBuilderState, handleClose }: ICustomFormBuilderProps) => {
  const [open, setOpen] = useState(false);
  const [saveanchorEl, setSaveAnchorEl] = useState<null | HTMLElement>(null);
  const [menuValue, setMenuValue] = useState('Draft');
  const openSaveoption = Boolean(saveanchorEl);
  const [openFieldDialog, setOpenFieldDialog] = useState(false);
  const [message, setMessage] = useState<IMessage[]>([]);
  const [currentForm, setCurrentForm] = useState<IForm>(createEmptyForm('', formBuilderState));
  const [formSchemas, setFormSchemas] = useState<Record<string, IForm> | undefined>(undefined);
  const [selectedFormComponent, setSelectedFormComponent] = useState<IForm | null>(null);
  const [selectedField, setSelectedField] = useState<IComponent | null>(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const { t } = useTranslation();
  const formioService = useMemo(() => new FormioService(), []);
  const [formVersion, setFormVersion] = useState<number>(0);
  const { showSnackBar } = useSnackBar();



  useEffect(() => {
    const fetchFormSchemas = async () => {
      try {
        const formsResponse = await formioService.getAllForm();
        setFormSchemas(filterNonWizardForms(formsResponse));
        setFormVersion((prevFormVersion) => prevFormVersion + 1);
      } catch (error) {
        showSnackBar(t('formCheck'), 'error');
      }
    };

    fetchFormSchemas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formioService, t]);


  useEffect(() => {
    const fetchFormData = async () => {
      if (formBuilderState.isEditingForm || formBuilderState.isWizardEditing) {
        try {
          const form = await formioService.get(row?.key || '');
          
          setCurrentForm(checkFieldValues(form));
          setMenuValue(form.savedOption || 'Draft');
          setFormVersion((prevFormVersion) => prevFormVersion + 1);
        } catch (error) {
          showSnackBar(t('formCheck'), 'error');
        }
      } else {
        setCurrentForm(createEmptyForm('', formBuilderState));
      }
    };
    fetchFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formBuilderState,
    row
  ]);

  const handleSaveClose = (menuValue: string) => {
    setMenuValue(menuValue || 'Draft');
    setSaveAnchorEl(null);
  };

  const handleSaveMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setSaveAnchorEl(event.currentTarget);
  };

  const handleFormSubmit = async (formJSON: IForm, savedOption?: string) => {
    const res = isFormValid({...formJSON});
    if (res.length > 0) {
      showSnackBar(res.join(', '), 'error');
      return;
    }
    try {
      const formKey = formJSON.key;
      const response: any = await formioService.save(formKey, { ...formJSON, savedOption: savedOption ?? 'Draft' });
      if (response) {
        setCurrentForm({ ...formJSON, ...response.form });
        setMessage([...response.messages]);
        setFormVersion((prevFormVersion) => prevFormVersion + 1);
        showSnackBar(
          t('formSave', {
            saveOption: savedOption
          }),
          'success'
        );
      } else {
        showSnackBar(t('formCheck'), 'error');
      }
    } catch (error) {
      showSnackBar(t('formCheck'), 'error');
    }
  };

  const fetchFormioData = async (formkey: string = '') => {
    return await formioService.get(formkey);
  };

  const handlePreviewToggle = () => setIsPreviewMode((prev) => !prev);

  const handleFormChange = (schema: any) => {
    setCurrentForm((prevForm) => ({ ...prevForm }));

    if (containsDummyComponent(schema.components)) {
      setOpen(true);
    }

    if (containsDummyField(schema.components)) {
      setOpenFieldDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedFormComponent(null);
    setCurrentForm((prevForm) => ({
      ...prevForm,
      components: removeDummyComponent(prevForm.components, false)
    }));
    setFormVersion((prevFormVersion) => prevFormVersion + 1);
  };

  const handleReplaceComponent = () => {
    setOpen(false);
    if (selectedFormComponent) {
      setCurrentForm((prevForm) => ({
        ...prevForm,
        components: removeDummyComponent(prevForm.components, true, selectedFormComponent)
      }));
      setSelectedFormComponent(null);
      setFormVersion((prevFormVersion) => prevFormVersion + 1);
    }
  };

  const handleSelectComponent = (formKey: string) => {
    const setFormComponents = async () => {
      const form: IForm = await fetchFormioData(formKey);
      setSelectedFormComponent(form);
    };
    setFormComponents();
  };

  const handleCloseFieldDialog = () => {
    setOpenFieldDialog(false);
    setSelectedField(null);
    setCurrentForm((prevForm) => ({
      ...prevForm,
      components: removeDummyField(prevForm.components, false)
    }));
    setFormVersion((prevFormVersion) => prevFormVersion + 1);
  };

  const handleReplaceField = () => {
    setOpenFieldDialog(false);
    if (selectedField) {
      setCurrentForm((prevForm) => ({
        ...prevForm,
        components: removeDummyField(prevForm.components, true, selectedField)
      }));

      setSelectedField(null);
      setFormVersion((prevFormVersion) => prevFormVersion + 1);
    }
  };

  const handleSelectField = (fieldInfo: FieldInfo) => {
    const setFormComponents = async () => {
      const fieldComponent: IComponent = await mapFieldInfoToComponent(fieldInfo);
      setSelectedField(fieldComponent);
    };
    setFormComponents();
  };

  useEffect(() => {
    if (selectedField) {
      handleReplaceField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField]);

  useEffect(() => {
    if (formBuilderState.isWizardEditing) {
      setIsPreviewMode(false);
    }
  }, [formBuilderState.isWizardEditing]);

  useEffect(() => {
    if (formBuilderState.isEditingForm) {
      setIsPreviewMode(false);
    }
  }, [formBuilderState.isEditingForm]);

  return (
    <Box sx={{ p: 3 }}>
      <FormBuilderControls
        handleClose={() => handleClose()}
        handlePreview={handlePreviewToggle}
        handlePreviewBack={handlePreviewToggle}
        isPreviewMode={isPreviewMode}
        handleSaveMenuClick={handleSaveMenuClick}
        handleSaveClose={handleSaveClose}
        handleFormSubmit={handleFormSubmit}
        openSaveoption={openSaveoption}
        saveanchorEl={saveanchorEl}
        menuValue={menuValue}
        currentForm={currentForm}
        isEditing={formBuilderState.isEditingForm || formBuilderState.isWizardEditing}
      />

      <FormFields
        currentForm={currentForm}
        setCurrentForm={setCurrentForm}
        isEditingForm={formBuilderState.isEditingForm}
        isWizardEditing={formBuilderState.isWizardEditing}
        isPreviewMode={isPreviewMode}
      />

      <FormDialog
        open={open}
        formSchemas={formSchemas}
        selectedFormComponent={selectedFormComponent}
        onClose={handleCloseDialog}
        onSelectComponent={handleSelectComponent}
        onReplaceComponent={handleReplaceComponent}
      />

      <FieldDialog
        open={openFieldDialog}
        selectedField={selectedField}
        onClose={handleCloseFieldDialog}
        onSelectField={handleSelectField}
      />

      <FormBuilderWrapper
        key={'builderWrapper' + isPreviewMode}
        form={currentForm}
        onChange={handleFormChange}
        options={{ noAlerts: true }}
        isPreviewMode={isPreviewMode}
      />

      <FormioReportOverview
        isPreviewMode={isPreviewMode}
        key={'reportoverview' + formVersion}
        formVersion={formVersion}
        reportMessage={message}
      />
    </Box>
  );
};

export default CustomFormBuilder;
