import { FormControl, InputAdornment } from '@mui/material';
import { OutlinedFormInput } from '../../atoms/OutlinedInput/OutlinedInput';
import Box from '@mui/material/Box';
import { InputTextField } from 'component/atoms/InputTextField/InputTextField';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { keyGenerator } from 'utils/Utils';
import { InputLable } from '../InputLabel/InputLabel';
import { IInputfieldProps } from './IInputfieldProps';
import ArrowTooltip from 'component/atoms/Tooltip/ArrowTooltip';

export function Inputfield(props: any) {
  const randomKey = keyGenerator();
  return (
    <Box>
      <div>
        <FormControl sx={{ typography: 'body2', mb: 2 }} fullWidth variant='standard'>
          <InputLable shrink htmlFor={`${randomKey}MuiInputfield`} sx={{ typography: 'body2' }}>
            {props.label}
          </InputLable>
          <OutlinedFormInput
            id={`${randomKey}MuiInputfield`}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            sx={{ background: '#fff' }}
            type={'text'}
            size='small'
          />
        </FormControl>
      </div>
    </Box>
  );
}

export function InputFormTextField(props: IInputfieldProps) {
  const randomKey = keyGenerator();
  return (
    <FormControl sx={{ mb: 2 }} fullWidth variant='standard'>
      <InputLable error={props?.error} shrink htmlFor={`${randomKey}MuiInputfield`} sx={{ typography: 'body2' }}>
        {props.label}
      </InputLable>
      <InputTextField
        error={props?.error}
        InputProps={{
          endAdornment: props.error ? (
            <InputAdornment position='end'>
              <WarningIcon color='error'></WarningIcon>
            </InputAdornment>
          ) : props.success ? (
            <InputAdornment position='end'>
              <CheckCircleIcon color='success'></CheckCircleIcon>
            </InputAdornment>
          ) : props.info ? (
            <InputAdornment position='end'>
              <ArrowTooltip title={props.info} arrow>
                <ErrorOutlineOutlinedIcon color='info'></ErrorOutlineOutlinedIcon>
              </ArrowTooltip>
            </InputAdornment>
          ) : (
            ''
          )
        }}
        fullWidth
      />
    </FormControl>
  );
}
