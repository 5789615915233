import React from 'react';
import { Grid, Card, CardContent, Typography, IconButton, Box, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ITileInfo } from '@ccs-dip/common/types/app-config';
import { getOverviewIcon } from 'utils/Utils';
import { OverviewLink, styles } from './InsuranceOverviewStyles';
import { useTranslation } from 'react-i18next';
import AddBoxIcon from '@mui/icons-material/AddBox';

interface InsuranceOverviewGridProps {
  filteredOverviewList: ITileInfo[];
  handleMenuOpen: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
  handleAddClick: () => void;
}

const InsuranceOverviewGrid: React.FC<InsuranceOverviewGridProps> = ({ filteredOverviewList, handleMenuOpen, handleAddClick }) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.contentBox}>
      <Grid 
        container 
        spacing={2}
        sx={{ alignItems: 'stretch' }} // Ensure all items stretch to match the tallest item in the row
      >
        {/* Map through the list of overview items */}
        {filteredOverviewList.map((overviewItem, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ minWidth: '300px' }}>
            <Card sx={{ ...styles.card, minHeight: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={(e) => handleMenuOpen(e, index)}>
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <OverviewLink to={overviewItem.url || '#'}>
                <Box sx={styles.iconBox}>
                  {getOverviewIcon(overviewItem.key)}
                  <Typography sx={styles.title}>{t(overviewItem.title)}</Typography>
                </Box>
                <CardContent>
                  <Typography variant='body2' color='text.secondary'>
                    {t(overviewItem.description)}
                  </Typography>
                </CardContent>
              </OverviewLink>
            </Card>
          </Grid>
        ))}

        {/* Add button with consistent height */}
        <Grid item xs={12} sm={6} md={4} sx={{ minWidth: '300px' }}>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
          >
            <Button
              variant='outlined'
              color='secondary'
              sx={{
                minHeight: '155px', // Set minimum height
                width: '100%', // Full width button to align properly
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleAddClick}
            >
              <AddBoxIcon sx={{ fontSize: '30px', mr: 1 }} />
              {t('add')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsuranceOverviewGrid;
