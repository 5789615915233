import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormSaveButton from './FormSaveButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IForm } from '@ccs-dip/common/types/formio-types';

export interface IFormBuilderControlsProps {
  handleClose: () => void;
  handlePreview: () => void;
  handlePreviewBack: () => void;
  handleSaveMenuClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSaveClose: (menuValue: string) => void;
  handleFormSubmit: (current: IForm, savedOption?: string) => void;
  openSaveoption: boolean;
  menuValue: string;
  saveanchorEl: null | HTMLElement;
  isPreviewMode: boolean;
  currentForm: IForm;
  isEditing: boolean;
}

const FormBuilderControls = ({
  handleClose,
  handlePreview,
  isPreviewMode,
  handleSaveMenuClick,
  handleSaveClose,
  handleFormSubmit,
  openSaveoption,
  menuValue,
  saveanchorEl,
  currentForm,
  isEditing
}: IFormBuilderControlsProps) => {
  const { t } = useTranslation();

  return (
    <Box
      id='FormBuilder'
      sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1, borderRadius: 1, position: 'sticky', gap: '20px' }}>
      <Box>
        <Button
          id='BackBtn'
          variant='outlined'
          color='secondary'
          onClick={isPreviewMode ? handlePreview : handleClose}
          sx={{ mr: 2, display: 'inline-flex' }}>
          <ArrowBackIcon sx={{ fontSize: '20px', mr: 1, color:(theme)=>theme.palette.secondary.main }} />
          {t('back')}
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, display: 'inline-flex', flexDirection: 'row-reverse' }}>
        <FormSaveButton
          handleSaveMenuClick={handleSaveMenuClick}
          handleSaveClose={handleSaveClose}
          handleFormSubmit={handleFormSubmit}
          saveanchorEl={saveanchorEl}
          openSaveoption={openSaveoption}
          menuValue={menuValue}
          currentForm={currentForm}
          isEditing={isEditing}
        />{' '}
      </Box>
      {!isPreviewMode && (
        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <Button variant='outlined'  color='secondary' onClick={handlePreview} sx={{ pr: 2 }}>
            <VisibilityIcon sx={{ fontSize: '20px', mr: 1, color:(theme)=>theme.palette.secondary.main }} />
            {t('preview')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FormBuilderControls;
