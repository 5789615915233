import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useKeycloak } from '../../../context/Keycloak/Keycloak';
import MiniDrawer from 'component/organisms/Drawer/MiniDrawer';
import BriefViewDrawer from 'component/organisms/Drawer/BriefViewDrawer';
import { StyledLayout, Wrapper } from './StyledLayout';
import { bottomMenu, menuItems } from 'utils/Constants';

import './Layout.css';

type JSXNode = JSX.Element | null;

interface ILayout {
  children?: React.ReactNode;
}

const Layout = (props: ILayout): JSXNode => {
  const location = useLocation();
  const showNavBar = !location.pathname.startsWith('/login');
  const navigate = useNavigate();
  const { authenticated, logout } = useKeycloak();
  const [selectedIndex, setSelectedIndex] = useState(0);
  // This code is to avoid reloading of the when the anchor tag is clicked;
  const formioEventListeners = () => {
    // Iterate through all anchor tags and bind click event
    document.addEventListener('click', (event: MouseEvent) => {
      if (!authenticated()) {
        logout();
      }
      const target = event.target as HTMLElement;
      if (target && target.closest('.navi-links')) {
        event.preventDefault();
        event.stopImmediatePropagation();
        const href = target.getAttribute('href');
        if (href) {
          const routingUrl = '/formrenderer/' + href;
          navigate(routingUrl);
        }
      }
    });
  };

  useEffect(() => {
    formioEventListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlHref = window.location.href;
    const activeIndex: any =
      menuItems.find((itm: any, index: any) => {
        const validate = new RegExp(itm.path, 'ig').test(urlHref);
        const validateForm = /formrenderer/gi.test(urlHref) && /formrenderer/gi.test(itm.path);
        const validatePartyDetails = /action/gi.test(itm.path) && new RegExp('/party/partydetails', 'ig').test(urlHref);
        if (validate || validatePartyDetails || validateForm) {
          itm.index = index;
          return itm;
        }
        return '';
      }) ||
      bottomMenu.find((itm: any) => {
        const validate = itm.path ? new RegExp(itm.path, 'ig').test(urlHref) : false;
        if (validate) {
          return itm;
        }
        return '';
      });
    if (activeIndex) {
      setSelectedIndex(activeIndex.index);
    } else {
      setSelectedIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <Box className='layoutCloak'>
      <Wrapper>
        {showNavBar ? (
          <MiniDrawer selectedIndex={selectedIndex} />
        ) : (
          ''
        )}
        <StyledLayout>{props.children}</StyledLayout>
        {showNavBar ? <BriefViewDrawer /> : ''}
      </Wrapper>
      <Box className='outletCloak' sx={{ width: '100%' }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
