import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Theme, Tooltip, Typography } from '@mui/material';
import UploadSwaggerFile from './SwaggerJsonUpload';
import { ChangeEvent, useState } from 'react';
import {
  clearFile,
  downloadFormAsJson,
  isValidjsonFile,
  isValidSwaggerFile,
  isValidSwaggerJson,
  readFileAsText,
  replaceJsonBlock,
  replaceWords,
  toJsonExtension
} from 'utils/Utils';
import { ISwaggerDataGrid } from './ISwaggerDataGrid';
import { displayForm, iconWrapper, titleText, toolBar, toolbarWrap } from './SwaggerGridStyledComponent';
import FormGridIcons from './SwaggerDataGridIcons';
import useSwaggerData from './useSwaggerData';
import { OpenApiService } from 'entities/OpenApi/OpenApiService';
import { Switch, FormControlLabel } from '@mui/material';
import yaml from 'js-yaml';
import CustomDataGridToolbar, { DataGridProSx } from 'utils/CustomDataGridToolbar';
import useSwaggerNotification from './SwaggerNotifications';
import { SwaggerFileInfo } from '@ccs-dip/common/types/swagger-types';
import useConfirmationDialog from './SwaggerDialog';

const SwaggerDataGrid: React.FC<ISwaggerDataGrid> = ({ handleRowClick }) => {
  const { rows, deleteSwagger, fetchSwagger } = useSwaggerData();
  const { t } = useTranslation();
  const { ConfirmationDialog, openDeleteDialog, openImportDialog } = useConfirmationDialog();
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>({});
  const { swaggerNotification } = useSwaggerNotification();
  const openApiService = new OpenApiService();

  const handleSwitchChange = async (isEnable: boolean, fileName: string) => {
    setSwitchStates((prevStates) => ({
      ...prevStates,
      [fileName]: isEnable
    }));
    await handleEnableSwagger(fileName);
  };

  const handleDeleteSwagger = async (key: string) => {
    const confirmed = await openDeleteDialog();
    if (confirmed) {
      deleteSwagger(key);
    } else {
      swaggerNotification.unableDelete();
    }
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (!selectedFile) {
      swaggerNotification.noFileSelected();
      return;
    }

    const fileName = selectedFile.name.replace(/\s/g, '');
    const fileSize = selectedFile.size;
    const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);
    const maxSizeInBytes = 10;

    if (parseFloat(fileSizeInMB) > maxSizeInBytes) {
      swaggerNotification.fileSizeError();
      clearFile(event);
      return;
    }

    const isJsonFile = isValidjsonFile(selectedFile);
    if (!isValidSwaggerFile(selectedFile)) {
      swaggerNotification.swaggerAcceptedFiles();
      clearFile(event);
      return;
    }

    try {
      const textFile = await readFileAsText<string>(selectedFile);

      // Temporary solution till webMethods fixes the swagger issue
      const updatedTextFile = replaceWords(textFile); // Temporary solution till webMethods fixes the swagger issue

      // Once fix then use textFile instead of updatedTextFile
      const swaggerData = isJsonFile ? JSON.parse(updatedTextFile) : null;

      // Temporary solution till webMethods fixes the swagger issue
      const updatedSwaggerData = replaceJsonBlock(swaggerData, 'PaymentOption'); // Once fix then remove this line

      if (isJsonFile && !isValidSwaggerJson(updatedSwaggerData)) {
        swaggerNotification.invalidStructure();
        clearFile(event);
        return;
      }

      if (await isFileNameExist(fileName)) {
        const confirmed = await openImportDialog();
        if (!confirmed) {
          handleUploadCancellation(event);
          return;
        }
      }
      await uploadSwaggerFile(toJsonExtension(fileName), isJsonFile ? updatedSwaggerData : yaml.load(updatedTextFile));
      handleSuccessfulUpload(event);
    } catch (error) {
      handleUploadError(error, event);
    }
  };

  const isFileNameExist = async (fileName: string): Promise<boolean> => {
    console.log(rows);
    const swaggerData: any = rows.find((itm: any) => itm.name.toLowerCase() === fileName.toLowerCase());
    return swaggerData;
  };

  const uploadSwaggerFile = async (fileName: string, swaggerData: SwaggerFileInfo): Promise<void> => {
    await openApiService.postSwagger(fileName, swaggerData, 'save');
  };

  const handleSuccessfulUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    clearFile(event);
    fetchSwagger();
    swaggerNotification.swaggerUpdate();
  };

  const handleUploadCancellation = (event: ChangeEvent<HTMLInputElement>): void => {
    clearFile(event);
  };

  const handleUploadError = (error: unknown, event: ChangeEvent<HTMLInputElement>): void => {
    console.error('File upload error:', error);
    clearFile(event);
    swaggerNotification.uploadError();
  };

  const handleEnableSwagger = async (fileName: string) => {
    try {
      // Perform save operation
      const swaggerData: SwaggerFileInfo = await openApiService.getSwaggerDetails(fileName);
      swaggerData.info.isEnable = !swaggerData.info.isEnable;
      await openApiService.postSwagger(fileName, swaggerData, 'save');
      fetchSwagger();
      swaggerNotification.swaggerUpdate();
    } catch (error) {
      console.error('File upload error:', error);
      swaggerNotification.swaggerError();
    }
  };

  const exportAsJson = async (fileName: string): Promise<void> => {
    try {
      const swagger: SwaggerFileInfo = await openApiService.getSwaggerDetails(fileName);
      swagger.name = fileName;
      downloadFormAsJson(swagger);
    } catch (error) {
      console.error('Error exporting form:', error);
    }
  };

  const renderNameCell = (params: any) => (
    <>
      <FormGridIcons.FolderSharedOutlinedIcon
        sx={{ color: (theme: Theme) => theme.palette.secondary.main, fontSize: '20px', mr: 1 }}
      />
      <Box title={params.row.name} sx={titleText}>
        {params.row.name}
      </Box>
    </>
  );

  const renderTitleCell = (params: any) => (
    <>
      <FormGridIcons.TextSnippetIcon
        sx={{ color: (theme: Theme) => theme.palette.secondary.main, fontSize: '20px', mr: 1 }}
      />
      <Typography component='span' sx={displayForm}>
        {t(params.row.title)}
      </Typography>
    </>
  );

  const renderVersionCell = (params: any) => {
    return (
      <>
        <FormGridIcons.StyleOutlinedIcon
          sx={{ color: (theme: Theme) => theme.palette.secondary.main, fontSize: '20px', mr: 1 }}
        />
        <Typography component='span' sx={displayForm}>
          {t(params.row.version)}
        </Typography>
      </>
    );
  };

  const renderActionCell = (params: any, exportAsJson: Function) => {
    const isEnable = switchStates[params.row.name] ?? params.row.isEnable;
    return (
      <>
        <FormControlLabel
          control={
            <Tooltip title={isEnable ? t('enable') : t('disable')}>
              <Switch
                checked={isEnable}
                onChange={() => handleSwitchChange(!isEnable, params.row.name)}
                color='primary'
              />
            </Tooltip>
          }
          sx={{ width: '40px' }}
          label=''
        />

        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDeleteSwagger(params.row.name);
          }}
          color='primary'
          aria-label='delete'>
          <Tooltip title={t('delete')}>
            <FormGridIcons.DeleteOutlineIcon sx={iconWrapper} />
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            exportAsJson(params.row.name);
          }}
          color='primary'
          aria-label='download'>
          <Tooltip title={t('export')}>
            <FormGridIcons.FileDownloadIcon sx={iconWrapper} />
          </Tooltip>
        </IconButton>
      </>
    );
  };
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      renderCell: renderNameCell
    },

    {
      field: 'title',
      headerName: t('title'),
      flex: 1,
      renderCell: renderTitleCell
    },
    {
      field: 'version',
      headerName: t('version'),
      flex: 1,
      renderCell: renderVersionCell
    },
    {
      field: 'delete',
      headerName: t('action'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => renderActionCell(params, exportAsJson)
    }
  ];

  return (
    <Box sx={{ py: 2 }}>
      <Box id='SwaggerGrid'>
        <DataGridPro
          sx={DataGridProSx}
          rows={rows}
          columns={columns}
          columnHeaderHeight={36}
          editMode='row'
          getRowId={(row: any) => row.name}
          disableColumnPinning
          pagination
          initialState={{
            sorting: {
              sortModel: [{ field: 'key', sort: 'asc' }]
            },
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          onRowClick={handleRowClick}
          pageSizeOptions={[
            10,
            20,
            40
          ]}
          slots={{ toolbar: CustomDataGridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              title: t('swaggerdatagrid')
            }
          }}
        />
      </Box>
      <Box sx={toolBar}>
        <Box sx={toolbarWrap}>
          <UploadSwaggerFile handleFileUpload={handleFileUpload} />
        </Box>
      </Box>
      {ConfirmationDialog}
    </Box>
  );
};

export default SwaggerDataGrid;
