import { FormLabel } from '@mui/material';

//===============================================
// styled syntax
//===============================================
export const inputLableStyle = {
  color: (theme: any) => theme.palette['primary'].main,
  fontSize: 14,
  textTransform: 'capitalize',
  '& label': {
    typography: 'body2'
  }
};

export const InputLable = (_props: any) => {
  return (
    <FormLabel sx={inputLableStyle} error={_props.error} htmlFor={_props.htmlFor}>
      {_props.children}
    </FormLabel>
  );
};
