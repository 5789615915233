import { useEffect, useState } from 'react';
import { createTheme, Theme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTheme } from 'store/theme/themeSlice';
import AppConfigService from 'entities/AppConfig/AppConfigService';
import { IAppConfig, IThemeConfig } from '@ccs-dip/common/types/app-config';
import { State } from 'store/store';

const useThemeConfig = () => {
  const dispatch = useDispatch();
  const [theme, setTheme] = useState<Theme>(createTheme());  // Default theme
  const defaultTheme = useSelector((state: State) => state.themeConfig.defaultTheme);
  const currentTheme = useSelector((state: State) => state.themeConfig.currentTheme);

  const applyTheme = (themeConfig:IThemeConfig) => {
    const root:any= document.querySelector(':root');
    const newTheme = createTheme(themeConfig.theme);
    setTheme(newTheme);
    root?.style?.setProperty('--theme-color', newTheme.palette.secondary.main);
  };

  useEffect(() => {
    // Apply the current theme from the Redux store
    if (currentTheme) {
      applyTheme(currentTheme);
    }
  }, [currentTheme]);

  useEffect(() => {
    const appConfigService = new AppConfigService();
    appConfigService.get().then((response: IAppConfig) => {
      const selectedTheme = response.themeconfigs?.find(
        (itm: IThemeConfig) => itm.isSelected
      );

      if (selectedTheme) {
        applyTheme(selectedTheme);
        dispatch(setCurrentTheme(selectedTheme));
      } else {
        applyTheme(defaultTheme);
        dispatch(setCurrentTheme(defaultTheme));
      }
    });
  }, [dispatch, defaultTheme]);

  return theme;
};

export default useThemeConfig;
